import Cookies from 'js-cookie'
import { USER_INFO } from "@/utils/constant";

const app = {
    namespaced: true,

    state: {
        // sidebar: { opened: true },<!-- // sidebar-改 -->
        sidebar: { opened: true },
        userAvatar: "",
        language: Cookies.get('language') || 'en',
        showLangChange: false, // 非修改项，如设为true则开启多语言切换功能，需要安装 i18n ，并将main.js内的相关代码放开注释
    },

    getters: {
        sidebar: state => state.sidebar,
        language: state => state.language,
        showLangChange: state => state.showLangChange,
        userAvatar: state => state.userAvatar,
    },

    mutations: {
        TOGGLE_SIDEBAR: (state, data) => {
            state.sidebar.opened = !state.sidebar.opened
        },
        userAvatar: (state, userAvatar) => {
            console.log(state, userAvatar);
        },

        SET_LANGUAGE: (state, language) => {
            state.language = language
            Cookies.set('language', language)
        },

        SET_isGoAuthOpen(state, isGoAuthOpen) {
            state.isGoAuthOpen = isGoAuthOpen;
        },

        SET_isHasPartyRouter(state, isHasPartyRouter) {
            state.isHasPartyRouter = isHasPartyRouter;
        }
    },

    actions: {
        toggleSideBar: ({ dispatch, commit, state }, data) => {
            commit('TOGGLE_SIDEBAR')
        },

        setLanguage({ commit }, language) {
            commit('SET_LANGUAGE', language)
        },
    }
}

export default app