/*
** 过滤器
**
** <template>
**     {{ 100 | number(2) }}
**     {{ '2016-01-28T01:57:22.000Z' | date('YYYY-MM-DD HH:MM:SS') }}
**     {{ '2016-01-28T01:57:22.000Z' | date('YYYY-mm-dd HHhh:mm:ss') }}
** </template>
** 
** ① 局部注册：
** <script>
** import Filters from '@/filter'
** export default {
**     filters: Filters
** }
** </script>
**
** ② 全局注册：main.js
** import Filter from '@/filter'
** Object.keys(Filters).forEach(key => {
**     Vue.filter(key, Filters[key])
** })
**
** ③ 全局挂载在 Vue 实例上(main.js)，再局部注册(xxx.vue)
** import Filter from '@/filter'
** Vue.prototype.$filter = Filter
**
** <script>
** export default {
**     filters: qc.$filter
** }
** </script>
**
*/

// 保留几位小数(四舍五入)
export const numberLimit = (value, decimal) => Number(value || 0).toFixed(decimal)

// 解决数字大小问题--取整数
export const priceIntFilter = value => ((value || 0).toString().split('.')[0] + '.')

// 解决数字大小问题--取小数
export const priceFloatFilter = value => (value || 0.00).toString().split('.')[1]

// 时间格式化(YYYY-MM(mm)-DD(dd) HH(hh):MI(mi):SS(ss))
export const dateTimeFilter = (value, rule) => {
    // 保持两位数
    let two = number => (number > 9 ? '' : '0') + number

    let time = new Date(value)
    // console.log(time)
    let YYYY = time.getFullYear()
    let mm = time.getMonth() + 1
    let dd = time.getDate()

    let hh = time.getHours()
    let mi = time.getMinutes()
    let ss = time.getSeconds()

    // 两位数
    let MM = two(time.getMonth() + 1)
    let DD = two(time.getDate())

    let HH = two(time.getHours())
    let MI = two(time.getMinutes())
    let SS = two(time.getSeconds())

    // 输出
    return rule.replace(/\:MM/g, ':MI').replace(/\:mm/g, ':mi')
        .replace('YYYY', YYYY)
        .replace('mm', mm)
        .replace('dd', dd)
        .replace('hh', hh)
        .replace('mi', mi)
        .replace('ss', ss)
        .replace('MM', MM)
        .replace('DD', DD)
        .replace('HH', HH)
        .replace('MI', MI)
        .replace('SS', SS)
}