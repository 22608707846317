// localstrage存储用户信息key值
export const USER_INFO = 'RELAY_SYSTEM_USER_INFO'

// vue全局变量名
export const PLAT_FORM_NAME = 'HOUSING_AUTHORITY_SYSTEM'

// 年级
export const GRADE_OPTIONS = [
    { id: 0, name: '0年级' },
    { id: 1, name: '1年级' },
    { id: 2, name: '2年级' },
    { id: 3, name: '3年级' },
    { id: 4, name: '4年级' },
    { id: 5, name: '5年级' },
    { id: 6, name: '6年级' },
    { id: 7, name: '7年级' },
    { id: 8, name: '8年级' },
    { id: 9, name: '9年级' },
    { id: 10, name: '10年级' },
    { id: 11, name: '11年级' },
    { id: 12, name: '12年级' }
]

// 角色ID
export const ROLE_TYPE = [
    { id: 1, name: '系统管理员' },
    { id: 2, name: '学校管理员' },
    { id: 3, name: '教师' },
    { id: 4, name: '学生' }
]

// 资源类目展现形式
export const DISPLAY_TYPE = [
    { id: 1, label: '图文', typeList: [], upload: false },
    { id: 2, label: 'PDF', typeList: ['pdf'], upload: true },
    { id: 3, label: '视频', typeList: ['mp4'], upload: true },
    { id: 4, label: '测试', typeList: [], upload: false },
    { id: 5, label: '软件包', typeList: ['zip', 'rar'], upload: true },
    { id: 6, label: 'word文档', typeList: ['doc', 'docx'], upload: true },
    { id: 7, label: 'PPT', typeList: ['ppt', 'pptx'], upload: true }
]

// 产品类目
export const PRODUCT_DISPLAY_TYPE = [
    { id: 1, label: '图文', typeList: [], upload: false },
    { id: 2, label: 'PDF', typeList: ['pdf'], upload: true },
    { id: 3, label: '视频', typeList: ['mp4'], upload: true }
]
