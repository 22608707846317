import Vue from "vue"
import Vuex from "vuex"

import app from './modules/app'
import schoolAuth from './modules/schoolAuth'

Vue.use(Vuex)

const store = new Vuex.Store({
	strict: true,   // 严格检测模式
	modules: {
		app,
		schoolAuth,
	}
})

export default store