// 判断学校的权限操作

// 1.超级管理员看到全部
    
// 2.学校管理员只看到本学校一栏，操作中看到购买资源（能看不能操作）、账号管理（能看不能操作）、
// 教师管理（能看不能操作）、学生管理、基本信息（能看不能操作）、测试概况

// 3.教师只看到本学校一栏，操作中看到购买资源（能看不能操作）、学生管理、测试概况

import { USER_INFO } from "@/utils/constant";

// 角色ID
export const ROLE_TYPE = [
    {id: 1, name: '系统管理员'},
    {id: 2, name: '学校管理员'},
    {id: 3, name: '教师'},
    {id: 4, name: '学生'},
]

const schoolAuth = {
    namespaced: true,
    state: {
        roleId: null,                   //  角色id

        teacherId: null,                // teacherId
        
        isCanResource: false,           //  能否购买资源
        isSeeResource: false,           //  能看教学资源

        isCanAccount: false,            //  能否账号管理
        isSeeAccount: false,            //  能看账号管理

        isCanTeacher: false,            //  能否教师管理
        isSeeTeacher: false,            //  能看教师管理
        
        isCanStudent: false,            //  能否学生管理

        isCanBase: false,               //  能否更改基本信息
        isSeeBase: false,               //  能看基本信息

        isCanTest: false,               //  能否查看测试概况
        isCanCreateTest: false,         //  能发起测试吗

        accountOptions: [],             //  添加账号所选择的角色

        isCanChangeAdminPw: false,      //  能否更改超级管理员密码
    },

    getters: {
        roleId: state => state.roleId,
        
        teacherId: state => state.teacherId,

        // 购买资源
        isCanResource(state) {
            return state.roleId === 1;
        },

        // 能看购买资源嘛
        isSeeResource(state) {
            return [1, 2].indexOf(state.roleId) !== -1;
        },

        // 账号管理
        isCanAccount(state) {
            return [1, 2, 3].indexOf(state.roleId) !== -1;
        },

        // 能进入账号管理看嘛
        isSeeAccount(state) {
            return [1, 2, 3].indexOf(state.roleId) !== -1;
        },

        // 教师管理
        isCanTeacher(state) {
            return [1, 3].indexOf(state.roleId) !== -1;
        },

        // 能看教师管理吗
        isSeeTeacher(state) {
            return [1, 2].indexOf(state.roleId) !== -1;
        },

        // 学生管理
        isCanStudent(state) {           //  发现然鹅没啥用，留着吧
            return [1, 2, 3].indexOf(state.roleId) !== -1;
        },
        
        // 能发起测试吗
        isCanCreateTest(state) {
            return state.roleId === 3;
        },
        
        //  更改学校基本信息
        isCanBase(state) {
            return state.roleId === 1;
        },

        // 能看基本信息吗
        isSeeBase(state) {
            return [1, 2].indexOf(state.roleId) !== -1;
        },
        
        // 查看测试概况
        isCanTest(state) {
            return [1, 2, 3].indexOf(state.roleId) !== -1
        },
        
        // 添加账号时的option
        accountOptions(state) {
            let options = [
                {id: 1, name: '系统管理员'},
                {id: 2, name: '学校管理员'},
                {id: 3, name: '教师'},
                {id: 4, name: '学生'},
            ]
            switch (state.roleId) {
                case 1:
                    options.splice(0, 1);
                    break;
                case 2:
                    options.splice(0, 2);
                    break;
                case 3:
                    options.splice(0, 3)
                    break;
                case 4:
                    options = [];
                    break
            }
            return options;
        },

        // 管理员密码
        isCanChangeAdminPw(state) {
            return state.roleId === 1;
        }
    },

    mutations: {
        SET_ROLEID(state, roleId) {
            state.roleId = roleId;
        },

        SET_TEACHERID(state, teacherId) {
            state.teacherId = teacherId;
        },
    },

    actions: {
        setRoleId({ commit }) {
            const userInfo = JSON.parse(window.localStorage.getItem(USER_INFO));
            const roleId = Number(userInfo.userInfo.roleName);
            if(roleId === 3) {
                const teacherId = Number(userInfo.userInfo.id);
                commit('SET_TEACHERID', teacherId);
            }
            commit('SET_ROLEID', roleId);
        }
    }
}

export default schoolAuth