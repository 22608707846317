import Vue from 'vue'
import Router from 'vue-router'
import routes from "./routes"
import { USER_INFO } from '@/utils/constant'
import { info } from '@/utils/action'
import store from "../store/index";
Vue.use(Router)

const router = new Router({
    mode: 'hash',
    routes: routes,
})

router.beforeEach((to, from, next) => {
    let userInfo = JSON.parse(window.localStorage.getItem(USER_INFO));
    if (userInfo && userInfo.resourceList.length && userInfo.token) {
        // 有登录状态并且有路由
        next()
    } else {
        const arr = ['/auth/login', '/auth/register', '/auth/reset']
        // 注：此处若直接用 next('/auth/login') ，则会报错：'Uncaught RangeError: Maximum call stack size exceeded'
        if (!arr.includes(to.path)) {
            info('登录状态失效，请重新登录！');
            next('/auth/login')
        } else {
            next()
        }
    }
})

router.afterEach((to, from) => {
    window.sessionStorage.setItem('currentCode', to.meta.code)
        // document.title = !meta || !meta.title ? ' ' : meta.title
})

export default router