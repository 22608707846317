/** 
 * @desc: 分块定义异步组件，使用AMD风格的require（相对 ../view）
 * @author: xugang
 * @date: 20191120
 * @example: const Home = resolve => require(['./view/Home.vue'], resolve)
 * @return routes = [
        { path: '/', component: resolve => require(['./view/home/index.vue'], resolve) },
        { path: '/order/list', component: resolve => require(['./view/order/list.vue'], resolve) }
    ]

 * @attentions:
 * 1、使用 parseRoutes() 将路由配置 config 解析成上述
 * 2、截取路由名称中 __& ，实现设置单路由title 和 code
 * 3、路由名称设置后，会截取路由，转成小驼峰，作为该路由的code码（比如：/order/detail/:id ---> orderDetail）
 * 2、菜单维护时，url为非必传项，但code码为必传项，且值需与注意③所述code保持完全一致，前后端需校验确保code唯一
 *  
 */

import {
    strFormat1
} from '@/utils' // 将 '-' 连接词转为大(小)驼峰格式

// 路由配置--基础菜单
let home_config = [
    '/auth/login__& 登录',
]

// 路由配置
let router_config = [
    "/system/school/index__& 学校管理##true##1",
    "/system/school/resource__& 购买资源##true##1.1",
    "/system/teacher/index__& 教师管理##true##1.2",
    "/system/account/list__& 账号管理##true##1.3",
    "/system/school/purchase__& 批量购买资源",
    "/system/school/changeTerm__& 批量更改有效期",
    "/system/account/add__& 账号管理",
    "/system/account/cancellation__& 账号管理",
    "/system/student/index__& 学生管理##true##1.4",
    "/system/student/testDetail__& 学生管理-测试详情",
    "/system/student/tested__& 学生管理-发起的测试",
    "/system/student/launchTest__& 学生管理-发起测试",

    "/system/role/index__& 角色管理##true##1",
    "/system/role/add__& 角色管理",
    "/system/test/index__& 教师管理-测试管理",
    "/system/test/create__& 教师管理-发起测试",

    "/category/product__& 产品类目##false##1",
    "/category/course__& 课程类目##false##1",
    "/category/grade__& 年级类目##false##1",
    "/category/hour__& 课时类目##false##1",
    "/category/resource__& 资源类目##false##1",

    "/home/index__& 首页配置##true##1",
    "/home/media__& 首页配置-全媒体中心",
    "/home/banner__& 首页配置-banner管理",
    "/home/product__& 首页配置-产品介绍",
    "/home/news__& 首页配置-资讯报道",
    "/home/level__& 首页配置-等级评测",
    "/home/cooperation__& 首页配置-合作案例",
    "/home/unit__& 首页配置-合作单位",
    "/home/friendship__& 首页配置-友情链接",
    "/home/footer__& 首页配置-尾页文字",

    "/content/index__& 内容管理##true##1",
    "/content/media/index__& 内容管理-全媒体中心荣誉",
    "/content/media/produce__& 内容管理-全媒体中心介绍",
    "/content/product/index__& 内容管理-产品管理##true##1.1",
    "/content/product/add__& 内容管理-产品管理",
    "/content/resource/index__& 内容管理-教育资源管理##true##1.2",
    "/content/resource/add__& 内容管理-教育资源管理",
    "/content/resource/editList__& 内容管理-教育资源管理",
    "/content/resource/examine/:id__& 内容管理-教育资源管理",
    "/content/product/record__& 内容管理-产品征订记录",
    "/content/solution/index__& 内容管理-教育解决方案##true##1.3",
    "/content/solution/add__& 内容管理-教育解决方案",
    "/content/cooperation/index__& 内容管理-合作案例管理##true##1.4",
    "/content/cooperation/add__& 内容管理-合作案例管理",
    "/content/active/index__& 内容管理-活动赛事列表##true##1.5",
    "/content/active/add__& 内容管理-活动赛事",
    "/content/active/joinList/:id__& 内容管理-参与详情",
    "/content/news/index__& 内容管理-资讯报道##true##1.6",
    "/content/news/add__& 内容管理-资讯报道",
    "/content/expertTeam/index__& 内容管理-专家团队##true##1.7",
    "/content/expertTeam/add__& 内容管理-专家团队",
    "/content/subject/index__& 内容管理-题库管理##true##1.8",
    "/content/subject/add__& 内容管理-题库管理",
    "/content/levelTest/index__& 内容管理-等级评测##true##1.9",
    "/content/levelTest/add__& 内容管理-等级评测",

    "/faq/list__& FAQ问题答疑##true##1",
    "/faq/add__& FAQ问题答疑新增",

    "/message/list__& 消息管理##true##1",
    "/message/add__& 消息管理",
    
    "/download/details__& 下载记录",
    "/download/examine__& 记录详情",

    "/popup/index__& 弹框提示##true##1",
    "/popup/add__& 弹框提示",

    // "/content/download/index__& 下载管理##true##1",
    // "/content/download/examine__& 下载管理",

    "/journal/index__& 系统提示##true##1",
]



// 定义路由
let routes = [],
    user_routes = [],
    relativePath = ''

user_routes.push({
    path: '/',
    component: resolve => require(['../components/Layout/index.vue'], resolve),
    children: [
        {
            path: '/',
            component: () => import('../view/system/school/index'),
            meta: {
                title: '学校管理',
                code: 'systemSchoolIndex'
            }
        },
        ...parseRoutes(router_config, ''),
    ]
})

routes = [...user_routes, ...parseRoutes(home_config)]

// 404 页面
routes.push({
    path: '*',
    component: () =>
        import ('../view/404.vue')
})

// 工厂函数：解析路由配置，返回routes数组
function parseRoutes(config, filefolder = '') {
    return [
        ...config.map(item => {
            let truePathArr = item.split('##');
            let array = truePathArr[0].split('__&')
            const fileName = array[0].replace(/\/(\:|\?)[A-z]+$/g, '')
            const fileNameFormatPre = fileName.replace(/\//, '')
            let param = {
                path: relativePath + array[0].replace(/(\/index|auth\/index)$/g, ''),
                component: () =>
                    import (`../view${fileName}.vue`),
                meta: {
                    title: array[1] && array[1].trim(),
                    code: strFormat1(fileNameFormatPre.split('/').join('-'), false),
                    keepAlive: truePathArr[1] == 'true' ? true : false,
                    rank: truePathArr[2] ? truePathArr[2] : 0,
                }
            }
            return param;
        })
    ]
}

export default routes