import Vue from 'vue'
import App from '@/App'
import router from '@/router'
import store from '@/store'
import ElementUI from 'element-ui'
// import 'element-ui/lib/theme-chalk/index.css'
import '@/assets/css/index.sass'
import './assets/iconfont/iconfont.css'
import './assets/css/style/theme/index.css'

import { PLAT_FORM_NAME } from '@/utils/constant'
// import i18n from '@/lang'
import * as Filters from '@/filter'

Vue.use(ElementUI, {
    size: 'medium',
    // i18n: (key, value) => i18n.t(key, value)
})

// 全局注册过滤器
Object.keys(Filters).forEach(key => {
    Vue.filter(key, Filters[key])
})

Vue.config.productionTip = false
    // 使用vue谷歌插件
Vue.config.devtools = true

window[PLAT_FORM_NAME] = new Vue({
    el: '#app',
    router,
    store,
    // i18n,
    render: h => h(App),
    data: {

    }
})