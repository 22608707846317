/**
 * @desc 判断数组是否有效
 * @author xugang
 * @param {Array} arr 目标数组
 * @return Boolean
 */

export const isValidArr = (arr) => (!arr || Object.prototype.toString.call(arr) !== '[object Array]' || !arr.length) ? false : true

/**
 * @desc 数组对象去重
 * @author xugang
 * @param {Array} arr 目标数组
 * @param {String} key 目标属性值
 * @return Array
 */

export const uniqueArrByKey = (arr, key) => {
  if (!isValidArr(arr)) return []

  var res = [], obj = {}
  for (var i = 0; i < arr.length; i++) {
    if (!obj[arr[i][key]]) {
      res.push(arr[i])
      obj[arr[i][key]] = true
    }
  }

  return res
}

/**
 * @desc format数组menu，剔除不显示在左侧导航内的菜单
 * @author xugang
 * @param {Array} arr 目标数组
 * @param {String} key 目标属性值
 * @return Array
 */

export const formatMenu = (arr, key) => {
  if (!isValidArr(arr)) return []

  for (var i = arr.length - 1; i >= 0; i--) {
    if (!arr[i][key]) {
      arr.splice(i, 1)
    } else {
      if (arr[i].userResourceList && arr[i].userResourceList.length) {
        formatMenu(arr[i].userResourceList, key)
      }
    }
  }
  return arr
}

/**
 * @desc 根据当前路由code，获取面包屑数组
 * @author xugang
 * @param {Array} menus 权限菜单
 * @param {String} code 当前路由code码
 * @return Array
 */
export const findBreadcrumbDataList = (menus = [], code) => {
  if (!isValidArr(menus)) return []

  let res = []
  menus.forEach(item => {
    if (item.code === code) {
      res.push(item)
    }
    if (item.childrenResourceList && item.childrenResourceList.length) {
      const subs = findBreadcrumbDataList(item.childrenResourceList, code)
      if (subs && subs.length > 0) {
        res = [item, ...subs]
      }
    }
  })
  return uniqueArrByKey(res, 'url')
}

/**
 * @desc 字符串格式 转 驼峰格式
 * @author xugang
 * @param {String} name 组件名
 * @param {Boolean} type 是否为大驼峰 (默认true为大驼峰， false为小驼峰)
 * @returns {String} 符合驼峰格式的名称
 */
export const strFormat1 = (name, type = true) => {
  name = type ? name.substring(0, 1).toUpperCase() + name.substring(1) : name;
  let idx = 0;
  do {
    idx = name.indexOf('-');
    let nextChar = name.substr(idx + 1, 1);
    name = name.replace('-' + nextChar, nextChar.toUpperCase());
  } while (idx > 0);
  return name;
}

/**
 * @desc 数组元素互换位置
 * @author xugang
 * @param {Array} arr 目标数组
 * @param {Number} index1 目标索引1
 * @param {Number} index2 目标索引2
 * @returns {Array} 转换后数组
 */
export const swapArr = (arr, index1, index2) => {
  arr[index1] = arr.splice(index2, 1, arr[index1])[0];
  return arr;
}

/**
 * @desc 数组元素置顶移动
 * @author xugang
 * @param {Array} arr 目标数组
 * @param {Number} index 目标索引
 */
export const toFirst = (arr, index) => {
  if(index !== 0 ) arr.unshift(arr.splice(index , 1)[0]);
}

/**
 * @desc 数组元素上移动一格
 * @author xugang
 * @param {Array} arr 目标数组
 * @param {Number} index 目标索引
 */
export const moveUp = (arr, index) => {
  if(index!== 0 ){
    arr[index] = arr.splice(index-1, 1, arr[index])[0];
  }else{
    arr.push(arr.shift());
  }
}

/**
 * @desc 数组元素下移动一格
 * @author xugang
 * @param {Array} arr 目标数组
 * @param {Number} index 目标索引
 */
export const moveDown = (arr, index) => {
  if(index!== arr.length-1){
    arr[index] = arr.splice(index+1, 1, arr[index])[0];
  }else{
    arr.unshift(arr.splice(index,1)[0]);
  }
}

/**
 * coocki操作封装
 * @type {{set: (function(*, *, *)), get: (function(*)), delete: (function(*=))}}
 */
export const coocki = {
  set(name, value, days) {
    const d = new Date()
    d.setTime(d.getTime() + 24 * 60 * 60 * 1000 * days)
    window.document.cookie = `${name}=${value};path=/;expires=${d.toGMTString()}`
  },
  get(name) {
    const v = window.document.cookie.match(`(^|;) ?${name}=([^;]*)(;|$)`)
    return v ? v[2] : null
  },
  delete(name) {
    this.set(name, '', -1)
  },
}
/**
 * localStorage操作封装
 */
export const storage = {
  get(key, def = '') {
    return JSON.parse(localStorage.getItem(key) || def)
  },
  set(key, obj) {
    localStorage.setItem(key, JSON.stringify(obj))
  },
  delete(key) {
    localStorage.removeItem(key)
  },
  clear() {
    localStorage.clear()
  },
}

